import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { Separator } from '../components/common/Separator';


export const About = () => {
    return (
        <Container className='mt-2 wrapper'>
            <Container className='mt-4 mb-3 center'>
                <h1><FontAwesomeIcon icon={faCircleInfo} />&nbsp;About</h1>
            </Container>
            <Separator />
            <Row>
                <Col className='mt-2 mb-2' md>
                    <p>
                        Dreamware Games is a small, one-man indie game development studio based in Oslo, Norway.
                        It’s founded and run by <a className='cstm-link' href='https://www.linkedin.com/in/reza-b-mirzaei/' target='_blank' rel='noopener noreferrer'>Reza Mirzaei</a>, a passionate gamer with a particular love for old-school pixel graphics.
                    </p>
                    {/* <p>
                        Besides game development, Reza has extensive experience in software development and management,
                        mastering languages such as C#, Java, and Python, and is well-versed in cloud technologies,
                        specifically Azure and GCP. This expertise not only enhances the robustness of game projects
                        but also eases the development process.
                    </p> */}
                </Col>
            </Row>
        </Container >
    )
}